import React, { useEffect, useState } from "react";
import {
  getMoniroFeaturedAuthors,
  getAuthors,
  updateAuthorState,
  addBookAuthor,
  removeBookAuthor,
} from "../../services/books.service";
// import AuthorCard from '../components/AuthorCard';
import Switch from "react-input-switch";
import { Modal} from "react-bootstrap";
import SearchBook from "../components/SearchBook";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import firebase from "../../services/firebaseConfig";
import { access_token, baseUrl } from "../../services/api";
import { nanoid } from "nanoid";
import Form from 'react-bootstrap/Form';

import { Table, Container, Row, Col } from 'react-bootstrap';

function DashboardCard34() {
  const [authors, setAuthors] = useState();
  const [featuredAuthors, setFeaturedAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState([]);
  const [search, setSearch] = useState(false);
  const [authorName, setAuthorName] = useState("");
  const [description, setDescription] = useState("");
  const [imageAsFile, setImageAsFile] = useState();
  const [open, setOpen] = React.useState(false);
  const [selectedAuthorEdit, setSelectedAuthorEdit] = useState();
  let imageUrls;
  const getMappedAuthor = (data) =>
    data.map(
      ({
        _id,
        userInfo,
        authorName,
        description,
        imageUrl,
        featured,
        followers,
        books,
      }) => {
        const { userName, userProfileUrl } =
          (userInfo && userInfo.length > 0) ? userInfo[0].userInfo : {};
        const userType =
          (userInfo && userInfo.length > 0) ? userInfo[0].usertype : '';
        return {
          _id,
          authorName,
          description,
          userName,
          imageUrl,
          userProfileUrl,
          userType,
          featured,
          followers,
          books: books ?? [],
        };
      }
    );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = async () => {
    try {
      const urlll = imageAsFile
        ? await uploadImage(imageAsFile)
        : selectedAuthorEdit.imageUrl;
      var profileData = {
        authorName: authorName.trim(),
        description: description.trim(),
        imageUrl: urlll,
      };
      if (profileData.imageUrl === "upload failed") {
        console.log("upload failed");
        return;
      }
      updateAuthorState(selectedAuthorEdit._id, "", "", profileData)
        .then((response) => {
          if (selectedAuthorEdit.featured) {
            setFeaturedAuthors(
              featuredAuthors.map((ddd) =>
                ddd._id === selectedAuthorEdit._id
                  ? { ...ddd, ...profileData }
                  : ddd
              )
            );
          } else {
            setAuthors(
              authors.map((ddd) =>
                ddd._id === selectedAuthorEdit._id
                  ? { ...ddd, ...profileData }
                  : ddd
              )
            );
          }
          setAuthorName("");
          setDescription("");
          setImageAsFile(undefined);
          setSelectedAuthorEdit(undefined);
          alert("Updated!");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      if (error.message === "Network Error") {
        alert("no internet connection");
      }
    }
    setOpen(false);
  };
  const clearEdit = () => {
    setAuthorName("");
    setDescription("");
    setImageAsFile(undefined);
    setSelectedAuthorEdit(undefined);
  };

  const handleCreate = async () => {
    try {
      const urlll = await uploadImage(imageAsFile);
      var profileData = {
        authorName: authorName.trim(),
        description: description.trim(),
        imageUrl: urlll,
        books: [],
        followers: [],
      };
      if (profileData.imageUrl === "upload failed") {
        return;
      }
      await axios
        .post(`${baseUrl}/admin/addAuthor`, profileData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })

        .then((response) => {
          setAuthors([...authors, getMappedAuthor([response.data])[0]]);
          clearEdit();
          alert("Added author!");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      if (error.message === "Network Error") {
        alert("no internet connection");
      }
    }
    setOpen(false);
  };
  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    if (image && image.size > 6 * 1024 * 1024) {
      alert("Please select an image with a size below 6MB.");
      setAuthors((prevData) => ({ ...prevData, imageUrl: '' }));
     
      return; 
    }
    setImageAsFile(image);
  };

  useEffect(() => {
    getMoniroFeaturedAuthors()
      .then((response) => {
        // console.log("RESPPONSE.getAuthors.PLATINUM", response.data);
        setFeaturedAuthors(getMappedAuthor(response.data));
      })
      .catch((reason) =>
        console.log("ERROR WITH REASON.getPopularGenres:", reason)
      );
    getAuthors()
      .then((response) => {
        // console.log("RESPPONSE.getAuthors.FREEMIUM", response.data);
        setAuthors(getMappedAuthor(response.data).filter(({featured}) => !featured));
      })
      .catch((reason) =>
        console.log("ERROR WITH REASON.getPopularGenres:", reason)
      );
  }, []);

  const uploadImage = async (image) => {
    try {
      // await handleUpload(image);

      // Your code for getting the download URL directly from Firebase Storage
      var user = firebase.auth().currentUser;
      // if (user != null) {
        let str = selectedAuthorEdit?.imageUrl;
        const shortid = `img_${
          str
            ? str.substring(str.indexOf("img_") + 4, str.indexOf("_author"))
            : nanoid()
        }_author`;
        const path = `/featuredAuthorImage/${shortid}`;
        const storage = firebase.storage();
        const ref = storage.ref().child(path);
        await ref.put(image);
        const url = await ref.getDownloadURL();
        return url;
      // }
      // return null;
    } catch (error) {
      console.error("Image upload failed:", error);
      return "upload failed";
    }
  };
const [searchQuery, setSearchQuery] = useState("");
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage] = useState(10);

// Filter authors based on the search query
const filteredAuthors = authors?.filter((author) =>
  author.authorName.toLowerCase().includes(searchQuery.toLowerCase())
);

// Paginate filtered authors
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentAuthors = filteredAuthors?.slice(indexOfFirstItem, indexOfLastItem);

// Determine total pages
const totalPages = Math.ceil((filteredAuthors?.length || 0) / itemsPerPage);



  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">Authors</h2>
      </header>
      <div class="col-md-12 ">
        <div class="col-md-9  ">
          <div class="login d-flex align-items-center py-5">
            <div class="container">
              <div class="row">
                <div class="col-lg-10 col-xl-12 mx-auto">
                  <center>
                    <h1 class="display-4" style={{ color: "black" }}>
                      {selectedAuthorEdit ? "Edit" : "Add"} Author
                    </h1>
                  </center>
       <Form>
      <Form.Group className="mb-6" controlId="exampleForm.ControlInput2">
        <Form.Label>Full Name </Form.Label>
        <Form.Control    style={{border: '1px solid grey', borderRadius: 5 }}
                        type="text"
                        id="firstName"
                        placeholder={"Enter Full Name"}
                        required
                        autoFocus
                        value={authorName}
                        onChange={(e) => setAuthorName(e.target.value)}
                         />
      </Form.Group>
      <Form.Group className="mb-6" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Description</Form.Label>
        <Form.Control as="textarea" 
        id="description"
        type="description"
        placeholder={"Enter Description"}
        required
        autoFocus
        max={10}
        maxLength={500}
        rows={5}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Upload Image</Form.Label>
        <Form.Control id="imageUrl"
                        type="file"
                        accept="image/png, image/jpeg"
                        placeholder={imageUrls}
                        name="imageUrl"
                        required=""
                        autoFocus
                        onChange={handleImageAsFile} />
      </Form.Group>


              <div className="row">
              <div className="col-md-6">
              <div
                        class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill"
                        style={{ backgroundColor: "blue", margin: "20px" }}
                        onClick={handleClickOpen}
                      >
                        Submit 
                  </div>
              </div>
              <div className="col-md-6">
              {selectedAuthorEdit && (
                      <div
                        class="btn btn-danger btn-block text-uppercase mb-2 rounded-pill"
                        style={{ margin: "20px" }}
                        onClick={clearEdit}
                      >
                        Clear
                      </div>
                    )}
              </div>
              </div>
                 
    </Form>
                  <form>
                    
                    

                    {/* <div class="custom-control custom-checkbox mb-3">
                                        <input id="customCheck1" type="checkbox" checked class="custom-control-input" />
                                        <label for="customCheck1" class="custom-control-label">Remember password</label>
                                    </div> */}
                    <div>
                     
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title"></DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you Sure ? Do you want to Add this user as
                            featured author?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} autoFocus>
                            Cancle
                          </Button>
                          <Button
                            onClick={() => {
                              selectedAuthorEdit
                                ? handleEdit()
                                : handleCreate();
                            }}
                          >
                            {selectedAuthorEdit ? "Update" : "Add"}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    {/* <div class="text-center d-flex justify-content-between mt-4"><p>Code by <a href="#" class="font-italic text-muted"> 
                                            <u>Jassa</u></a></p></div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{width: 'auto'}}>
        <h1 style={{marginLeft: 10, color: 'blue'}}>
          Featured Authors
          {featuredAuthors && featuredAuthors.length == 0 ? ": empty" : ""}
        </h1>
       
            <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12}>
              <Table responsive="xl"  className="my-6" >
              <thead>
                <tr>
                 
                  <th>Author Name</th>
                  <th>Add Book</th>
                  <th>Status</th>
                  <th>Featured</th>
                </tr>
              </thead>
              {featuredAuthors &&
          featuredAuthors.map((author) => (
              <tbody>
                <tr>
               
                  <td>{author.authorName}</td>
                  <td> 
                    <Button
                    onClick={() => {
                      setSelectedAuthor(author._id);
                      setSearch(true);
                    } }
                  >
                    Books
                  </Button></td>
                  <td>
                  <Button
                    onClick={() => {
                      setAuthorName(author.authorName);
                      setDescription(author.description);
                      setSelectedAuthorEdit(author);
                    } }
                  >
                    Edit
                  </Button>
                  </td>
                  
                  <td> 
                    <Switch
                    value='0'
                    on="0"
                    off="1"
                    onChange={() => {
                      updateAuthorState(
                        author._id,
                        "featured",
                        false
                      ).then((response) => {
                        setFeaturedAuthors(featuredAuthors.filter(({ _id }) => _id != author._id));
                        setAuthors([...authors, { ...author, featured: false }]);
                      });
                    } } /></td>
                </tr>
               
              </tbody>
              ))}
            </Table>
              </Col>
            </Row>
          </Container>
           
         
        <h1 style={{marginLeft: 10, color: 'blue'}} >
          All Authors {authors && authors.length == 0 ? ": empty" : ""}
        </h1>
       
            <Container fluid>
        

            <Row className="justify-content-center">
  <div className="d-flex justify-content-end mb-4">
    <input
      type="text"
      style={{
        marginTop: '10px',
        width: '300px',
        borderRadius: '5px',
      }}
      placeholder="Search authors..."
      className="form-control"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  </div>
  <Col xs={12}>
    <Table responsive="xl" className="my-6">
      <thead>
        <tr>
          <th>Author Name</th>
          <th>Add Book</th>
          <th>Status</th>
          <th>Featured</th>
        </tr>
      </thead>
      <tbody>
        {currentAuthors
          ?.filter((author) =>
            author.authorName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) // Filter by name
          )
          .map((author) => (
            <tr key={author._id}>
              <td>{author.authorName}</td>
              <td>
                <Button
                  onClick={() => {
                    setSelectedAuthor(author._id);
                    setSearch(true);
                  }}
                >
                  Books
                </Button>
              </td>
              <td>
                <Button
                  onClick={() => {
                    setAuthorName(author.authorName);
                    setDescription(author.description);
                    setSelectedAuthorEdit(author);
                  }}
                >
                  Edit
                </Button>
              </td>
              <td>
                <Switch
                  value={author.featured ? "1" : "0"}
                  on="1"
                  off="0"
                  onChange={() => {
                    updateAuthorState(author._id, "featured", !author.featured).then(() => {
                      setAuthors((prevAuthors) =>
                        prevAuthors.map((item) =>
                          item._id === author._id
                            ? { ...item, featured: !item.featured }
                            : item
                        )
                      );
                    });
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
    <div className="d-flex justify-content-center align-items-center mt-3">
      <Button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((prev) => prev - 1)}
      >
        Previous
      </Button>
      <span className="mx-3">Page {currentPage} of {totalPages}</span>
      <Button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((prev) => prev + 1)}
      >
        Next
      </Button>
    </div>
  </Col>
</Row>

          </Container>
      
       
      </div>
      <Modal
        show={search}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add books
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchBook
            primaryAction={(book) =>
              addBookAuthor(selectedAuthor, book._id).then((response) => {
                setFeaturedAuthors(
                  featuredAuthors.map((featuredAuthor) => ({
                    ...featuredAuthor,
                    books: [...featuredAuthor.books, book],
                  }))
                );
                // return response.data;
              })
            }
            secondaryAction={(book) =>
              removeBookAuthor(selectedAuthor, book._id).then((response) => {
                setFeaturedAuthors(
                  featuredAuthors.map((featuredAuthor) => ({
                    ...featuredAuthor,
                    books: featuredAuthor.books.filter(
                      (bk) => bk._id != book._id
                    ),
                  }))
                );
                // return response.data;
              })
            }
            books={
              selectedAuthor?featuredAuthors.find(({ _id }) => _id == selectedAuthor)
                ?.books ?? []:[]
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {setSearch(!search);
                    setSelectedAuthor(undefined);}
                  } className="text-green-500">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DashboardCard34;
