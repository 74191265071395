import React, { useState, useEffect } from 'react';
import { Button, Spinner, Alert, Table } from 'react-bootstrap';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaEdit, FaTrash } from 'react-icons/fa'; 
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import { baseUrl, access_token } from '../services/api';

function PrivacyAndPolicy() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [combinedPolicy, setCombinedPolicy] = useState('');
  const [combinedTermsAndConditions, setCombinedTermsAndConditions] = useState('');
  const [eula, setEula] = useState('');
  const [authorServiceAgreement, setAuthorServiceAgreement] = useState('');
  
  const [incomingData, setIncomingData] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/admin/getsystemsettings`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      setIncomingData(response.data);
    } catch (error) {
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async (item) => {
    setCombinedPolicy(item.privacyPolicy || '');
    setCombinedTermsAndConditions(item.termsAndConditions || '');
    setEula(item.endUserLicenseAgreement || '');
    setAuthorServiceAgreement(item.authorServiceAgreement || '');
    setEditingId(item._id);
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      if (editingId) {
        await axios.put(`${baseUrl}/admin/updatesystemsetting`, {
          privacyPolicy: combinedPolicy,
          termsAndConditions: combinedTermsAndConditions,
          endUserLicenseAgreement: eula,
          authorServiceAgreement: authorServiceAgreement
        }, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
      } else {
        await axios.post(`${baseUrl}/admin/privacy-policy`, {
          privacyPolicy: combinedPolicy,
          termsAndConditions: combinedTermsAndConditions,
          endUserLicenseAgreement: eula,
          authorServiceAgreement: authorServiceAgreement
        }, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
      }
      setSuccess(true);
      setEditingId(null);
      
      fetchData();
    } catch (error) {
      setError('Failed to save data');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (itemId) => {
    setLoading(true);
    try {
      await axios.delete(`${baseUrl}/admin/${itemId}/deletesystemsetting`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      setSuccess(true);
      fetchData();
    } catch (error) {
      setError('Failed to delete data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="p-6">
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">Operation successful!</Alert>}

          <form>
            <div className="mb-3">
              <label style={{ fontWeight: 'bold', paddingTop: '20px' }}>Privacy and Policy</label>
              <ReactQuill
                value={combinedPolicy}
                onChange={setCombinedPolicy}
                modules={modules}
                style={{ height: '200px', paddingBottom: '20px' }}
              />
            </div>

            <div className="mb-3">
              <label style={{ fontWeight: 'bold', paddingTop: '20px' }}>Terms and Conditions</label>
              <ReactQuill
                value={combinedTermsAndConditions}
                onChange={setCombinedTermsAndConditions}
                modules={modules}
                style={{ height: '200px', paddingBottom: '20px' }}
              />
            </div>

            <div className="mb-3">
              <label style={{ fontWeight: 'bold', paddingTop: '20px' }}>End User License Agreement (EULA)</label>
              <ReactQuill
                value={eula}
                onChange={setEula}
                modules={modules}
                style={{ height: '200px', paddingBottom: '20px' }}
              />
            </div>

            <div className="mb-3">
              <label style={{ fontWeight: 'bold', paddingTop: '20px' }}>Author Service Agreement</label>
              <ReactQuill
                value={authorServiceAgreement}
                onChange={setAuthorServiceAgreement}
                modules={modules}
                style={{ height: '200px', paddingBottom: '20px' }}
              />
            </div>

            <Button
              onClick={handleSave}
              disabled={loading}
              variant={editingId? 'warning':'info'}
              style={{ marginTop: '20px' }}
            >
              {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
            </Button>
          </form>

          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Privacy & Policy</th>
                <th>Terms & Conditions</th>
                <th>EULA</th>
                <th>Author Service Agreement</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody style={{ height: '100px', overflowY: 'auto', whiteSpace: 'normal' }}>
                <tr>
                  <td style={{ maxHeight: '100px', overflowY: 'auto', whiteSpace: 'normal' }}  dangerouslySetInnerHTML={{ __html: incomingData.privacyPolicy }} />
                  <td style={{ maxHeight: '10px', overflowY: 'auto', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: incomingData.termsAndConditions }} />
                  <td style={{ maxHeight: '10px', overflowY: 'auto', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: incomingData.endUserLicenseAgreement }} />
                  <td style={{ maxHeight: '10px', overflowY: 'auto', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: incomingData.authorServiceAgreement }} />
                  <td style={{ display: 'flex', gap: '10px' }}>
                    <FaEdit
                      style={{ cursor: 'pointer', color: 'green' }}
                      onClick={() => handleEdit(incomingData)}
                    />
                    {/* //<FaTrash */}
                      {/* //style={{ cursor: 'pointer', color: 'red' }}
                      //onClick={() => handleDelete(incomingData._id)} */}
                    {/* ///> */}
                  </td>
                </tr>
            </tbody>
          </Table>
        </main>
      </div>
    </div>
  );
}

export default PrivacyAndPolicy;
